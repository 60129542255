<template>
  <div class="card fd-px-6 fd-py-4">
    <div @click="back" class="fd-w-full fd-flex fd-items-center fd-cursor-pointer">
      <SvgLoader :name="'arrowLeftIcon'" />
      <span class="fd-block fd-ml-1 fd-font-semibold fd-text-sm fd-text-theme-2">{{ $t('profile.editProfile') }}</span>
    </div>
    <div @click="selectFile" class="fd-relative fd-cursor-pointer fd-w-24 fd-h-24 fd-rounded-full fd-overflow-hidden fd-mt-8">
      <img v-if="getNewImageUrl || thumbnail" class="fd-w-full fd-h-full" :src="getNewImageUrl ? getNewImageUrl : thumbnail" alt="avatar">
      <span v-else class="fd-block fd-w-full fd-h-full fd-bg-gray-400"></span>
      <span class="fd-block fd-w-full fd-h-full fd-absolute fd-left-0 fd-top-0 fd-bg-white fd-opacity-70"></span>
      <SvgLoader name="exportIcon2" class="fd-absolute" style="left: 50%; top: 50%; transform: translate(-50%, -50%)" />
    </div>
    <div class="fd-w-full fd-flex fd-items-center fd-mt-11">
      <div class="fd-w-1/3 fd-flex fd-items-center">
        <span class="fd-block fd-ml-1 fd-font-bold fd-text-sm fd-text-theme-2">{{ $t('profile.photoProfile') }}</span>
      </div>
      <div class="fd-w-2/3 fd-flex fd-items-start fd-justify-start">
        <input type="file" ref="file" @change="changeFile" style="display: none">
        <b-button @click="selectFile" variant="primary" class="fd-mr-4 btn-custom">{{ $t('global.upload') }}</b-button>
        <b-button @click="resetFile" variant="outline-primary" class="btn-custom">{{ $t('global.reset') }}</b-button>
      </div>
    </div>
    <div>

    </div>
    <div class="fd-mt-6">
      <div class="fd-w-full fd-border-t fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="firstName" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.firstName') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="firstName"
            :placeholder="$t('global.firstName')"
            v-model="form.first_name"
          />
        </div>
      </div>

      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="lastName" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.lastName') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="lastName"
            :placeholder="$t('global.lastName')"
            v-model="form.last_name"
          />
        </div>
      </div>

      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="position" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.position') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="position"
            :placeholder="$t('global.position')"
            v-model="form.position"
            :disabled="true"
          />
        </div>
      </div>

      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="address" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.address') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="address"
            :placeholder="$t('global.address')"
            v-model="form.address"
          />
        </div>
      </div>

      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="email" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.email') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="email"
            :placeholder="$t('global.email')"
            v-model="form.email"
            :disabled="true"
          />
        </div>
      </div>

      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="phone" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('global.phone') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="phone"
            :placeholder="$t('global.phone')"
            v-model="form.phone"
          />
        </div>
      </div>
      <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8 fd-pr-3">
        <b-button @click="save" variant="primary">{{ $t('global.save') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormInput, BButton} from 'bootstrap-vue'
export default {
  name: "Profile",
  components: {BFormInput, BButton},
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        position: '',
        address: '',
        email: '',
        phone: ''
      },
      thumbnail: '',
      image: null,
      newImageUrl: null,
      prevPage: null
    }
  },
  created() {
    this.$store.dispatch('user/getUser', 1).then(() => {
      let user = this.$store.getters["user/user"]
      this.form.first_name = user.first_name
      this.form.last_name = user.last_name
      this.form.position = user.role
      this.form.address = user.address
      this.form.email = user.email
      this.form.phone = user.phone
      this.thumbnail = user.thumbnail
    })
  },
  computed: {
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    save() {
      let formData = new FormData()

      if (this.image) {
        formData.append('avatar', this.image)
      }
      Object.keys(this.form).forEach(key => {
        let blackList = ['position', 'email']
        if (!blackList.includes(key)) {
          formData.append(key, this.form[key])
        }
      })
      this.$store.dispatch('user/updateUser', {
        id: 1,
        data: formData
      })
    },
    selectFile() {
      this.$refs.file.click()
    },
    changeFile() {
      this.image = this.$refs.file.files[0]
      this.newImageUrl = URL.createObjectURL(this.image)
      this.$refs.file.value = ''
    },
    resetFile() {
      this.image = null
      this.newImageUrl = null
    },
    upload() {
      if (!this.newImageUrl) {
        this.selectFile()
        return
      }
      this.$store.dispatch('user/updateUser', {
        id: 1,
        data: {
          file: this.newImageUrl
        }
      })
    },
    back() {
      this.$router.push({ path: this.prevPage.path })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevPage = from
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep button.btn.btn-custom {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
</style>